import React from 'react';
import Header from '../../../../components/header/Header';
import logo from '../../assets/logo_structural_concept.png';
import withStyles from 'react-jss';

type HeaderType = typeof Header;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore exotic component
const HeaderSC: HeaderType = (props) => (
  <Header {...props} logoIcon={logo} title="" />
);
const styles = {
  '@global': {
    '@media (min-width: 440px)': {
      'img.logo': {
        maxHeight: '35px',
      },
    },
    'header .login.MuiButton-containedPrimary': {
      display: 'none',
    },
  },
};

export default withStyles(styles)(HeaderSC);
