import React from 'react';
import { withPolyglot } from '../../../../i18n';
import Polyglot from 'node-polyglot';
import { STModal } from '../../../../components/commons/Modal';
import { CallIcon, LocationIcon } from '../../assets';
import { Box, Paper, Typography, styled } from '@mui/material';

export type Props = {
  polyglot: Polyglot;
  showContactUs: boolean;
  closeContactUs(...args: unknown[]): unknown;
};

function ContactUs(props: Props) {
  const { polyglot, showContactUs, closeContactUs } = props;
  return (
    <STModal
      open={showContactUs}
      onClose={closeContactUs}
      title={polyglot.t('contact_us.title')}
      fullWidth
      maxWidth="md"
    >
      <Box color={'primary.main'}>
        <Typography textAlign="center" variant="h6" fontWeight={'bold'}>
          By phone, email or in person - we’re always here for you.
        </Typography>
        <Typography textAlign="center">
          You can contact our offices from Monday to Friday, between 8:30 AM and
          4:30 PM.
        </Typography>
        <Paper
          elevation={1}
          variant="elevation"
          sx={{
            mt: 2,
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr',
            },
          }}
        >
          <Box textAlign="center" padding={4}>
            <LocationIcon height={'4em'} />
            <Typography variant="h6" mt={3} fontWeight={'bold'}>
              Plant 1 (Main Office)
            </Typography>
            <Typography variant="body1">888 E. Porter Rd.</Typography>
            <Typography variant="body1">Muskegon, MI 49441</Typography>
            <Typography variant="h6" fontWeight={'bold'}>
              Plant 2
            </Typography>
            <Typography variant="body1">5566 Grand Haven Rd.</Typography>
            <Typography variant="body1">Norton Shores, MI 49441</Typography>
            <Typography variant="h6" fontWeight={'bold'}>
              Plant 3
            </Typography>
            <Typography variant="body1">
              1269 East Mt. Garfield Suite F
            </Typography>
            <Typography variant="body1">Norton Shores, MI 49441</Typography>
          </Box>

          <SegmentInvertedStyled textAlign="center" padding={4}>
            <CallIcon height={'4em'} />
            <Typography variant="h6" mt={3} fontWeight={'bold'}>
              Toll Free
            </Typography>
            <Typography variant="body1">1-800-433-9489</Typography>
            <Typography variant="h6" fontWeight={'bold'}>
              Local
            </Typography>
            <Typography variant="body1">231-798-8888</Typography>
            <Typography variant="h6" fontWeight={'bold'}>
              Fax
            </Typography>
            <Typography variant="body1">231-798-4960</Typography>
          </SegmentInvertedStyled>
        </Paper>
      </Box>
    </STModal>
  );
}

const SegmentInvertedStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

export default withPolyglot(ContactUs);
