import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';
import BrowserUtil from '../../../../util/BrowserUtil';
import ConnectContactUs from '../../../../components/contactUs';
import ConnectFleetManagerInfo from '../../../../components/LandingPage/fleetManagerInfo';
import ConnectRecipesInfo from '../../../../components/LandingPage/recipesInfo';
import { withPolyglot } from '../../../../i18n';
import imageLandingPageDesktop from '../../assets/SC_background.jpg';
import { Box, Button } from '@mui/material';
import { DeviceGroupIcon, InfoIcon, LoginIcon } from '../../assets';

const browserUtil = new BrowserUtil();
const isPhone = browserUtil.getIsPhone();
const isMobile = browserUtil.getIsMobile();

export class LandingPage extends Component {
  /**
   * Columns grid boxes isMobile
   * in mobile devices tablet and phones by the landscape orientation the boxes should be
   * showed in the same row. by the portrait rotation the boxes will be showed vertically
   */

  static columnsGridBoxesMobile() {
    return browserUtil.getOrientation() === browserUtil.getLandscape() ? 5 : 9;
  }

  constructor(props) {
    super(props);
    this.orientationChange = this.orientationChange.bind(this);
    this.openContactUs = this.openContactUs.bind(this);
    this.closeContactUs = this.closeContactUs.bind(this);
    this.closeFleetManagerInfo = this.closeFleetManagerInfo.bind(this);
    this.openRecipesInfo = this.openRecipesInfo.bind(this);
    this.closeRecipesInfo = this.closeRecipesInfo.bind(this);
    this.selectPicturesGalery = this.selectPicturesGalery.bind(this);
    this.state = {
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
      showContactUs: false,
      showFleetManagerInfo: false,
      showRecipesInfo: false,
    };
  }
  componentDidMount() {
    window.addEventListener('orientationchange', this.orientationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.orientationChange);
  }
  selectPicturesGalery() {
    const imagesGalleryLandingPage = [{ original: imageLandingPageDesktop }];
    return imagesGalleryLandingPage;
  }

  /**
   * Orientation change
   * this function will be called by a orientation change between landscape and Portrait
   * in mobile devices
   */
  orientationChange() {
    this.setState({
      imagesGalleryLandingPage: this.selectPicturesGalery(),
      columnsGridBoxesMobile: LandingPage.columnsGridBoxesMobile(),
    });
  }

  openContactUs() {
    this.setState({
      showContactUs: true,
    });
  }

  closeContactUs() {
    this.setState({ showContactUs: false });
  }

  openClickLogin = () => {
    const { loggedIn, history } = this.props;
    if (!loggedIn) {
      history.push('/login');
    } else {
      history.push('/devicemanager');
    }
  };

  closeFleetManagerInfo() {
    this.setState({ showFleetManagerInfo: false });
  }

  openRecipesInfo() {
    this.setState({ showRecipesInfo: true });
  }

  closeRecipesInfo() {
    this.setState({ showRecipesInfo: false });
  }

  render() {
    const { polyglot, loggedIn } = this.props;
    const {
      showContactUs,
      showFleetManagerInfo,
      showRecipesInfo,
      imagesGalleryLandingPage,
      columnsGridBoxesMobile,
    } = this.state;
    return (
      /* the id=container is used to get the hight of this element in order to
       * change the position of the footer: absolute or relative
       */
      <div className="landing-page">
        <ConnectContactUs
          polyglot={polyglot}
          showContactUs={showContactUs}
          closeContactUs={this.closeContactUs}
        />
        <ConnectFleetManagerInfo
          polyglot={polyglot}
          showFleetManagerInfo={showFleetManagerInfo}
          closeFleetManagerInfo={this.closeFleetManagerInfo}
        />
        <ConnectRecipesInfo
          polyglot={polyglot}
          showRecipesInfo={showRecipesInfo}
          closeRecipesInfo={this.closeRecipesInfo}
        />

        <div className="container padded">
          <div className="background-images">
            <ImageGallery
              items={imagesGalleryLandingPage}
              showThumbnails={false}
              showBullets={false}
              showNav={false}
              showFullscreenButton={false}
              showPlayButton={false}
              autoPlay
              slideDuration={4000}
              Id="imageGallery"
            />
          </div>
          <div className="information">
            <Grid centered columns={16} padded>
              <Grid.Column
                textAlign="center"
                verticalAlign="middle"
                computer={4}
                widescreen={3}
                largeScreen={3}
                tablet={12}
                mobile={12}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.openClickLogin}
                  tabIndex="0"
                  role="link"
                  sx={{
                    padding: 2,
                    borderRadius: '50%',
                    width: '200px',
                    height: '200px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '1.5em',
                    textTransform: 'initial',

                    // invert colors
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText,
                    '&:hover': {
                      backgroundColor: (theme) =>
                        theme.palette.primary.contrastText,
                      color: (theme) => theme.palette.primary.dark,
                    },
                  }}
                >
                  {!loggedIn ? (
                    <>
                      <LoginIcon height={'2.5em'} />
                      <Box padding={1.5}>
                        {polyglot.t('device.login.button_title')}
                      </Box>
                    </>
                  ) : (
                    <Box
                      sx={{
                        mt: '0.5em',
                      }}
                    >
                      <DeviceGroupIcon height={'52.5px'} />
                      <Box
                        padding={1}
                        sx={{
                          lineHeight: '1.2em',
                        }}
                      >
                        {polyglot.t('landing_page.fleet_manager')}
                      </Box>
                    </Box>
                  )}
                </Button>
              </Grid.Column>
              <Grid.Column
                textAlign="center"
                verticalAlign="middle"
                computer={4}
                widescreen={3}
                largeScreen={3}
                tablet={12}
                mobile={12}
              >
                <Button
                  variant="contained"
                  onClick={this.openContactUs}
                  tabIndex="0"
                  role="link"
                  sx={{
                    padding: 2,
                    borderRadius: '50%',
                    width: '200px',
                    height: '200px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '1.5em',
                    textTransform: 'initial',
                  }}
                >
                  <InfoIcon height={'2.5em'} />
                  <Box padding={1.5}>
                    {polyglot.t('landing_page.contact_us')}
                  </Box>
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  polyglot: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool,
};

LandingPage.defaultProps = {
  loggedIn: false,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withPolyglot(LandingPage)));
