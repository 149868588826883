import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import React from 'react';
import { createTheme } from '@mui/material/styles';
import { componentsOverrides } from '../../../theme/ThemeProviderWithTheme';
import './fontface.scss';

const PRIMARY_COLOR = '#284C68';
const PRIMARY_COLOR_ACCENT = '#B5C4C7';
export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      // Nav color
      main: PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily:
      '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"',
  },
  components: {
    ...componentsOverrides,
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: PRIMARY_COLOR_ACCENT,
          color: PRIMARY_COLOR,
          '&:hover': {
            backgroundColor: PRIMARY_COLOR,
            color: 'white',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily:
            '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"!important',
        },
      },
    },
  },
});

export default function ThemeProviderWithTheme({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
