import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import { withSnackbar } from 'stoerk-ui-components';
import BrowserUtil from '../../../../../../util/BrowserUtil';
import RightsUserUtil from '../../../../../../util/rights/RightsUserUtil';
import ConnectDevicesOverviewGrid from './DevicesOverviewGrid';
import DevicesOverviewGridSmall from './DevicesOverviewGridSmall';
import ConnectDevicesOverviewList from './DevicesOverviewList';
import { withPolyglot } from '../../../../../../i18n';
import { withUserRightUtil } from '../../../../../../util/rights';
import {
  VIEW_GRID,
  VIEW_LIST,
  VIEW_GRID_SMALL,
} from '../../../../../commons/topBarMenu';
import Polyglot from 'node-polyglot';
import { OpenSnackbarProps } from '../../../../../../handlingErrors';
import { Device } from '../../../../../../model/device/device.model';
import { STButtonIcon as Button } from '../../../../../commons/STButtonIcon';
import { RootState } from '../../../../../../redux/store.model';
import { ConnectedProps, connect } from 'react-redux';
import TooltipLight from '../../../../../commons/TooltipLight';

const componentsDevicesOverview = {
  [VIEW_GRID]: ConnectDevicesOverviewGrid,
  [VIEW_LIST]: ConnectDevicesOverviewList,
  [VIEW_GRID_SMALL]: DevicesOverviewGridSmall,
};

const browserUtil = new BrowserUtil();

const funFalse = () => false;
type Props = {
  polyglot: Polyglot;
  rightsUserUtil: RightsUserUtil;
  devices: Device[];
  view: typeof VIEW_GRID | typeof VIEW_LIST | typeof VIEW_GRID_SMALL;
  groupId?: string;
  openEditDevice(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: Device
  ): unknown;
  openDeviceUI(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: Device
  ): unknown;
  openDeviceOverviewPage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: Device
  ): unknown;
  openIncidentManager(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: Device
  ): unknown;
  openDeviceInformation(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: Device
  ): unknown;
  openDeviceSensordata(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: Device
  ): unknown;
} & OpenSnackbarProps &
  ConnectedComponentProps;

type State = {
  sizeIcons: 'medium';
};
/**
 * Devices overview
 */
export class DevicesOverview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getDeviceInfoIcon = this.getDeviceInfoIcon.bind(this);
    this.getDeviceEditIcon = this.getDeviceEditIcon.bind(this);
    this.getDeviceUIIcon = this.getDeviceUIIcon.bind(this);
    this.getDeviceAlarmsIcon = this.getDeviceAlarmsIcon.bind(this);
    this.getDeviceCloudConnectivityIcon =
      this.getDeviceCloudConnectivityIcon.bind(this);
    this.getDeviceSensordataIcon = this.getDeviceSensordataIcon.bind(this);
    this.getDeviceLabel = this.getDeviceLabel.bind(this);
    this.loadButtonsToDisplay = this.loadButtonsToDisplay.bind(this);
    this.loadButtonsByDeviceToDisplay =
      this.loadButtonsByDeviceToDisplay.bind(this);

    const sizeIcons = 'medium';
    this.state = {
      sizeIcons,
    };
  }

  /**
   * Get device info icon
   * show the device information icon
   * @param array device
   * @return object deviceInfoIcon
   */
  getDeviceInfoIcon(device: Device) {
    const { sizeIcons } = this.state;
    const { polyglot, openDeviceInformation } = this.props;

    const button = (
      <Button
        icon="question circle outline"
        size={sizeIcons}
        onClick={(event) => openDeviceInformation(event, device)}
        loading={device.loadingData}
      />
    );
    let deviceInfoIcon = button;
    if (!browserUtil.getIsMobile()) {
      deviceInfoIcon = (
        <TooltipLight title={polyglot.t('device.tooltip.device_information')}>
          {button}
        </TooltipLight>
      );
    }
    return deviceInfoIcon;
  }

  /**
   * Get device edit icon
   * show the device edit icon
   * @param array device
   * @return object deviceEditIcon
   */
  getDeviceEditIcon(device: Device) {
    const { sizeIcons } = this.state;
    const { polyglot, openEditDevice } = this.props;
    const button = (
      <Button
        icon="write"
        size={sizeIcons}
        onClick={(event) => openEditDevice(event, device)}
        loading={device.loadingData}
      />
    );
    let deviceEditIcon = button;
    if (!browserUtil.getIsMobile()) {
      deviceEditIcon = (
        <TooltipLight title={polyglot.t('device.tooltip.open_window_edit')}>
          {button}
        </TooltipLight>
      );
    }

    return deviceEditIcon;
  }

  /**
   * Get device ui icon
   * show the device ui icon
   * @param array device
   * @return object deviceUIIcon
   */
  getDeviceUIIcon(device: Device) {
    const { sizeIcons } = this.state;
    const { polyglot, openDeviceUI } = this.props;
    const button = (
      <Button
        icon="calculator"
        size={sizeIcons}
        onClick={(event) => openDeviceUI(event, device)}
        disabled={!device.cloudConnectivity}
        loading={device.loadingData}
      />
    );
    let deviceUIIcon = button;
    if (!browserUtil.getIsMobile()) {
      deviceUIIcon = (
        <TooltipLight title={polyglot.t('device.tooltip.open_window_ui')}>
          {button}
        </TooltipLight>
      );
    }

    return deviceUIIcon;
  }

  /**
   * Get device alarms icon
   * show the device alarms icon
   * @param array device
   * @return object deviceAlarmsIcon
   */
  getDeviceAlarmsIcon(device: Device) {
    const { sizeIcons } = this.state;
    const { polyglot, openIncidentManager } = this.props;

    const button = (
      <Button
        icon="warning sign"
        size={sizeIcons}
        onClick={(event) => openIncidentManager(event, device)}
        loading={device.loadingIncidentsUnresolved}
        badge={device.incidents.length}
      />
    );
    let deviceAlarmsIcon = null;
    if (!browserUtil.getIsMobile()) {
      deviceAlarmsIcon = (
        <TooltipLight title={polyglot.t('device.tooltip.open_window_alarms')}>
          {button}
        </TooltipLight>
      );
    } else {
      deviceAlarmsIcon = button;
    }
    return deviceAlarmsIcon;
  }

  getDeviceSensordataIcon(device: Device) {
    const { sizeIcons } = this.state;
    const { polyglot, openDeviceSensordata } = this.props;
    const button = (
      <Button
        icon="chart line"
        size={sizeIcons}
        onClick={(event) => openDeviceSensordata(event, device)}
        loading={device.loadingData}
      />
    );
    let sensordataIcon = button;
    if (!browserUtil.getIsMobile()) {
      sensordataIcon = (
        <TooltipLight
          title={polyglot.t('device.tooltip.open_window_sensordata')}
        >
          {button}
        </TooltipLight>
      );
    }
    return sensordataIcon;
  }

  /**
   * Get device cloud connectivity icon
   * show the device cloud connectivity icon
   * @param array device
   * @return object deviceAlarmsIcon
   */
  getDeviceCloudConnectivityIcon(device: Device) {
    const { sizeIcons } = this.state;
    const { polyglot } = this.props;
    const cloudConnectivityIcon = (
      <TooltipLight title={device.cloudConnectivityTooltip}>
        <Button
          cursor="initial"
          loading={device.loadingStatus}
          size={sizeIcons}
        >
          <i className="icon">
            <img
              src={device.cloudConnectivityIcon}
              className="icon-status"
              alt={`${device.cloudConnectivityTooltip} ${polyglot.t(
                'device.icon'
              )}`}
            />
          </i>
        </Button>
      </TooltipLight>
    );
    return cloudConnectivityIcon;
  }

  getItemDisabled(device: Device) {
    return !device.cloudConnectivity;
  }

  /**
   * Get devicel label:
   * if the device has a field label, it will be shown
   * @param object device
   */
  getDeviceLabel(device: Device) {
    if (!device.label) {
      return null;
    }
    const { polyglot, view } = this.props;
    let label = polyglot.t(`device.label.${device.label}`);
    /* by mobile we don't have much space at the left side. Since the Label is
    placed there, we should show only a symbol to indicate that there is a label
    */
    if (browserUtil.getIsMobile() && view === VIEW_LIST) {
      label = '*';
    }
    return (
      <div
        className={`label-device ${device.label} ${
          browserUtil.getIsMobile() ? 'mobil' : ''
        }`}
      >
        {label}
      </div>
    );
  }

  /**
   * Load buttons by device to display:
   * this function loads the rights to show the buttons for a device
   * rights to read incidents
   */
  loadButtonsByDeviceToDisplay(deviceId: string) {
    const { groupId, rightsUserUtil } = this.props;
    const showButtonReadIncidents = rightsUserUtil.hasRightsToReadIncident(
      deviceId,
      groupId
    );
    const response = { [deviceId]: showButtonReadIncidents };
    return response;
  }

  /**
   * Load buttons to display:
   * this function loads the rights to show the buttons for each device
   */
  loadButtonsToDisplay() {
    const { devices, rightsUserUtil, groupId } = this.props;
    let showButtonReadIncidentsDevices: Record<string, boolean> = {};

    const devicesR = devices.map((device) =>
      this.loadButtonsByDeviceToDisplay(device.uuid)
    );
    devicesR.forEach(
      (device) =>
        (showButtonReadIncidentsDevices = {
          ...showButtonReadIncidentsDevices,
          ...device,
        })
    );
    const deviceInfoEnabled = rightsUserUtil.isSupportUser();
    const showDeviceSensordataIcon = rightsUserUtil.hasRightsToReadHistory();
    const showDeviceEditIcon = (deviceId: string) =>
      rightsUserUtil.hasRightsToUpdateDevice(deviceId, groupId);
    return {
      deviceInfoEnabled,
      showButtonReadIncidentsDevices,
      showDeviceSensordataIcon,
      showDeviceEditIcon,
    };
  }

  render() {
    const {
      view,
      openDeviceOverviewPage,
      devices,
      featureToggle,
      openDeviceUI,
    } = this.props;
    const {
      deviceInfoEnabled,
      showButtonReadIncidentsDevices,
      showDeviceSensordataIcon,
      showDeviceEditIcon,
    } = this.loadButtonsToDisplay();
    const DevicesOverviewView = componentsDevicesOverview[view];
    return (
      <div className="DevicesOverview">
        <DevicesOverviewView
          devices={devices}
          deviceInfoEnabled={deviceInfoEnabled}
          showButtonReadIncidentsDevices={showButtonReadIncidentsDevices}
          getDeviceInfoIcon={this.getDeviceInfoIcon}
          getDeviceEditIcon={this.getDeviceEditIcon}
          showDeviceEditIcon={showDeviceEditIcon}
          getDeviceUIIcon={this.getDeviceUIIcon}
          getDeviceAlarmsIcon={this.getDeviceAlarmsIcon}
          getDeviceCloudConnectivityIcon={this.getDeviceCloudConnectivityIcon}
          showDeviceSensordataIcon={showDeviceSensordataIcon}
          getDeviceSensordataIcon={this.getDeviceSensordataIcon}
          getDeviceLabel={this.getDeviceLabel}
          onClickItem={
            !!featureToggle.ControlUnitsOverview
              ? openDeviceOverviewPage
              : openDeviceUI
          }
          itemDisabled={
            !!featureToggle.ControlUnitsOverview
              ? funFalse
              : this.getItemDisabled
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  featureToggle: state.featureToggle,
});

const connector = connect(mapStateToProps, {});

export type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withSnackbar(
  withPolyglot(withUserRightUtil(connector(DevicesOverview)))
);
